$block: '.sidebar';
$element: 'nav';
$modifier: '--dynamic';

#{$block} {
	&-toggler {
		display: inline-block;

		&--right {
			color: $secondary-color;
			padding: 10px;
		}
	}

	&-icon {
		-webkit-animation: slide 1s ease-out;
		-webkit-animation-iteration-count: infinite;
		cursor: pointer;
		font-size: 26px;
		position: absolute;
		right: 5px;
		top: 12px;
	}

	&-serverTime {
		font-size: 14px;
		font-weight: bold;
	}

	&-theme {
		padding-bottom: 20px;

		&Text {
			font-size: 20px;
		}

		&Switcher {
			&Label {
				display: inline-block;
				margin-right: 70px;
				width: 0;
			}

			&--off {
				position: absolute;
				left: 70px;
				color: $dark-theme-color;
			}

			&--on {
				position: absolute;
				right: 70px;
				color: $dark-theme-color;
			}
		}
	}

	&-counter {
		&Title {
			display: inline-block;
			font-size: 20px;
			vertical-align: middle;

			&Wrapper {
				display: inline;
			}

			@at-root .sidebar-counterTitleWrapper #{$block}-nav {
				&Panel {
					&--close {
						right: 5px;
						position: absolute;
						top: 5px;
						width: 30px;
						height: 30px;
						padding: 2px;
						font-size: 28px;
					}
				}
			}

		}

		&Player {
			display: inline-block;
			font-size: 16px;

			&--eu,
			&--na,
			&--sanctuary {
				color: $secondary-color;
			}
		}

		&Servers {
			padding-left: 28px;
			text-align: left;
		}

		&Refresh {
			background-color: transparent;
			border: none;
			height: 30px;
			padding: 5px;
			position: absolute;
			left: 5px;
			top: 5px;
			width: 30px;

			&:hover,
			&:focus {
				background-color: transparent;
			}

			& span {
				display: block;
				font-size: 18px;
			}
		}
	}
}