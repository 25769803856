$block: '.light-theme';

html#{$block} {
	background-color: $light-theme-bg-color;
}

#{$block} {
	& .side-nav,
	& .header,
	& nav,
	& .modal,
	& .donation-modal .donation-modalFooter,
	& .donation-disc,
	& .donation-cart,
	& .dropdown-content,
	& .modal .modal-footer {
		background-color: $light-theme-bg-color;
	}

	& .hws-connect-npcCount {
		bottom: 5px;
		padding: 1px;
		font-size: 12px;
		background-color: rgba(255, 255, 255, 0.77);
		position: absolute;
		border-radius: 7px;
	}

	& #PPMiniCart form,
	& #PPMiniCart ul,
	& .commands-text .commands-search {
		background-color: $light-theme-bg-color !important;
	}

	& .commands-search:focus {
		color: $light-theme-color !important;
	}

	& nav ul a,
	& .content-text,
	& .content,
	& .container,
	& .sidebar-counterTitle,
	& .sidebar-counterPlayer,
	& .sidebar-themeText,
	& .discord-title,
	& .discord-channel,
	& .discord-user,
	& .sidebar-counterTitleWrapper,
	& .header-navListItemLink--mobile,
	& .sidebar-themeSwitcher--off,
	& .sidebar-themeSwitcher--on,
	& .modal,
	& .modal p,
	& .dropdown-content li>a,
	& .dropdown-content li>span,
	& .card-reveal p,
	& .donation-contentText,
	& .donation-contentText p,
	& .donation-contentTitle,
	& .donation-calc,
	& .input-field label,
	& .commands-text,
	& .ecc-label,
	& .ecc-text,
	& .ecc p,
	& .ecc-resultNumber,
	& .base li,
	& .base p,
	& .base h3,
	& .base h4,
	& .footer-copyright,
	& .hws-connect-cardContent p,
	& .hws-connect-profileDd,
	& .hotview-modal h1,
	& .hotview-modal h2,
	& .hotview-modal h3,
	& .hotview-modal h4,
	& .hotview-modal p,
	& .hws-connect-am-infoText,
	& .sidebar-serverTime {
		color: $light-theme-color;
	}

	& .hws-connect-button {
		&--invert {
			color: $light-theme-color !important;
			border: 3px solid $light-theme-bg-color-dark !important;

			&:hover {
				background-color: $light-theme-bg-color-dark !important;
			}

			&:focus {
				color: $light-theme-color !important;
				background-color: $light-theme-bg-color !important;
			}
		}

		&--neutral {
			&:focus,
			&:active {
				background-color: $secondary-color !important;
			}
		}
	}

	& .hwsc-content li:hover,
	& .ts-header:hover {
		background-color: $light-theme-bg-color-dark;
	}

	& .footer {
		background-color: $dark-theme-color;
		border-top: 1px solid $line-color;
	}

	& .footer nav {
		background-color: $dark-theme-color;
	}

	& .content-textLine {
		background-color: $line-color;
	}

	& .select-dropdown li.disabled,
	& .select-dropdown li.disabled>span {
		color: $line-color;
	}

	& .select-dropdown li.disabled:hover {
		background-color: $light-theme-bg-color !important;
	}

	& #PPMiniCart .minicart-item a,
	& .minicart-closer,
	& .minicart-quantity,
	& .minicart-empty-text {
		color: $light-theme-color !important;
	}

	& .header-nav--active {
		background: none;
		background-color: $primary-color;
		color: $white;
	}

	& .header-navListItem,
	& .header-navListItem--mobile {
		& a:hover {
			color: $white;
		}

		&:hover {
			background: none;
			background-color: $primary-color;
			color: $white;
		}
	}

	& .sidebar-toggler--right {
		color: $primary-color;
	}

	& .modal strong {
		color: $secondary-color;
	}

	& .ct {
		color: $dark-theme-color !important;

		&:hover {
			background-color: $light-theme-bg-color !important;
			color: $light-theme-color !important;
		}

		&:hover span {
			color: $light-theme-color !important;
		}
	}

	& .discord-join,
	& .discord-join a,
	& .donation-modalFooterAccept,
	& .donation-info,
	& .donation-infoButton,
	& .ecc-calc,
	& .hws-connect-button {
		color: $dark-theme-color;

		&:hover,
		&:hover a,
		&:hover span {
			color: $light-theme-color;
		}

		&:active {
			background-color: $primary-color;
			color: $dark-theme-color;
		}
	}

	& .footer-navListItem {
		border-bottom: 1px solid $dark-theme-color;

		&:hover {
			border-bottom: 1px solid $primary-color;
		}
	}

	& .discord-userlist::-webkit-scrollbar,
	& .discord-userlist::-webkit-scrollbar-track {
		background-color: $line-color;
	}

	& .discord-userlist::-webkit-scrollbar-thumb {
		background-color: $primary-color-dark;
	}

	& .card,
	& .card-reveal,
	& .select-dropdown.dropdown-content li:hover,
	& .card .card-reveal {
		background-color: $white;
		color: $light-theme-color;
	}
}