.slider {
	height: 400px;
	position: relative;
	width: 100%;

	// Fullscreen slider
	&.fullscreen {
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;

		ul.slides {
			height: 100%;
		}

		ul.indicators {
			bottom: 30px;
			z-index: 2;
		}
	}

	.slides {
		background-color: $slider-bg-color;
		height: 400px;
		margin: 0;

		li {
			height: inherit;
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;

			img {
				background-position: center;
				background-size: cover;
				height: 100%;
				width: 100%;
			}

			.caption {
				color: #fff;
				left: 10%;
				opacity: 0;
				position: absolute;
				text-shadow: 1px 1px 1px $black;
				top: 15%;
				width: 80%;

				p {
					color: $white;
					font-size: 20px;
				}
			}

			&.active {
				z-index: 2;
			}
		}
	}


	.indicators {
		bottom: 0;
		left: 0;
		margin: 0;
		position: absolute;
		right: 0;
		text-align: center;

		.indicator-item {
			background-color: transparent;
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-slider-dot.png");
			background-size: cover;
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			height: 22px;
			margin: 0 12px;
			position: relative;
			transition: all .3s ease-out;
			width: 22px;

			&.active {
				background-color: transparent;
				transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
			}
		}
	}

	&-arrow {
		position: absolute;
		color: $dark-theme-color;
		top: 30%;
		z-index: 700;
		cursor: pointer;

		&--prev {
			left: 10px;
		}

		&--next {
			right: 10px;
		}
	}

	// for mobile devices
	@media #{$small-and-down} {
		&-arrow {
			font-size: 50px;
		}
	}


	// for tablet devices
	@media #{$medium-and-up} {
		&-arrow {
			font-size: 90px;
		}
	}


	// for desktop devices
	@media #{$large-and-up} {
		&-arrow {
			font-size: 125px;
		}
	}

}