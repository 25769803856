$block: '.donation';

#{$block} {
	&-right {
		color: $dark-theme-color;
		height: 100%;
		overflow: auto;
		padding: 70px 15px 400px 15px;
		position: fixed;
		right: 0;
		width: 35%;

		ul li {
			line-height: 26px;
			list-style-type: disc;
		}
	}

	&-left {
		color: $dark-theme-color;
		margin-top: 70px;
	}

	&-warning {
		color: $secondary-color;
		font-weight: bold;
	}

	&-revealText {
		& ul {
			padding: 20px;

			& li {
				list-style-type: disc;
			}
		}
	}

	&-dpCounter {
		font-weight: bold;
		color: $primary-color;

		&--inactive {
			font-weight: bold;
			color: $line-color;
		}
	}

	&-disc {
		background-color: $background-color-dark;
		border: 1px solid $line-color;
		height: 100px;
		position: fixed;
		right: -50px;
		top: 20px;
		transform: rotate(45deg);
		width: 100px;
		z-index: 100;

		&Button {
			color: $secondary-color;
			font-size: 30px;
			position: absolute;
			right: 37px;
			top: 54px;
			transform: rotate(-45deg);
		}
	}

	&-cart {
		background-color: $background-color-dark;
		border: 1px solid $line-color;
		bottom: -50px;
		height: 100px;
		position: fixed;
		right: -50px;
		transform: rotate(45deg);
		width: 100px;
		z-index: 1000;

		&Button {
			color: $primary-color;
			font-size: 30px;
			position: absolute;
			right: 64px;
			top: 26px;
			transform: rotate(-45deg);
		}
	}

	&-card {
		cursor: pointer;

		&--active {
			border: 3px solid $secondary-color;
		}

		&Image {
			max-height: 30% !important;
		}

		&Title {
			font-size: 15px !important;
		}
	}

	&-content ul li {
		list-style-type: disc;
		margin-left: 17px;
	}

	&-input-field {
		margin-top: 22px;
	}

	&-modal {
		background-color: $background-color;
		color: $dark-theme-color;

		& &Footer {
			background-color: $background-color;
		}

		& strong {
			color: $secondary-color;
		}

		&Footer {
			margin-bottom: 15px;

			&Accept {
				background: $primary-color;
				border: 3px solid $primary-color;
				box-shadow: 0 0 0 $primary-color,0 5px 0 $primary-color-dark,0 5px 0 $primary-color-dark;
				color: $white;
				cursor: pointer;
				display: block;
				font-size: 18px;
				font-weight: 700;
				height: 50px;
				line-height: 40px;
				margin: 0 auto;
				padding: 0 10px;
				position: relative;
				text-align: center;
				text-decoration: none;
				transition: all 150ms ease;
				-webkit-transition: all 150ms ease;
				width: 150px;

				&:hover {
					background: transparent;
					color: $white;
					text-decoration: none;
				}

				&:active {
					animation: none;
					-webkit-animation: none;
					box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
					transform: translateY(3px);
					-webkit-transform: translateY(3px);
				}

				&:focus {
					background: $primary-color;
				}
			}
		}

		@at-root .modal {
			background-color: $background-color;
		}
	}

	&-calc {
		margin-bottom: 30px;
	}

	&-info {
		&Button {
			background: $primary-color;
			border: 3px solid $primary-color;
			box-shadow: 0 0 0 $primary-color,0 5px 0 $primary-color-dark,0 5px 0 $primary-color-dark;
			color: $white;
			cursor: pointer;
			display: block;
			font-size: 16px;
			font-weight: 700;
			height: 30px;
			line-height: 22px;
			margin: 0 auto;
			padding: 0 10px;
			position: relative;
			text-align: center;
			text-decoration: none;
			transition: all 150ms ease;
			-webkit-transition: all 150ms ease;

			&:hover {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&:active {
				animation: none;
				-webkit-animation: none;
				box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}

			&:focus {
				background: $primary-color;
			}
		}
	}

	&-image {
		width: 100%;
	}

	&-contentText {
		& .donation-image {
			max-width: 40%;
		}

		& a {
			color: $primary-color;
			text-decoration: underline;
		}
	}

	&-price {
		color: $secondary-color;
		font-size: 40px;
		font-weight: bold;
	}

	// for mobile devices
	@media #{$small-and-down} {
		&-right {
			display: none;
		}

		&-disc {
			top: 10px;
		}
	}


	// for tablet devices
	@media #{$medium-and-up} {
		&-arrow {
			font-size: 90px;
		}

		&-more {
			display: none;
		}
	}


	// for desktop devices
	@media #{$large-and-up} {
		&-arrow {
			font-size: 125px;
		}

		&-more {
			display: none;
		}
	}
}

$module: '.donation'; // lp = landing page

#{$module} {

	&-price {

		&-info {
			display: block;
		}
	}

	&-loader {
		display: none;
	}

	&-input {
		&:focus:not([readonly]) {
			border-bottom: 1px solid $primary-color !important;
		}

		&Error {
			border-bottom: 3px solid $error-color !important;
		}
	}

	&-supporterTitle {
		color: $secondary-color;
		font-weight: bold;
		padding: 10px 0 0 0;
	}

	&-supporterHeaderS {
		color: $secondary-color;
	}

	&-result-re,
	&-result-bp {
		display: none;
		margin-top: 30px;
	}

	&-supporterBody,
	&-loader {
		margin-top: 1rem;
	}

	&-supporterBodyGet {
		display: none;
	}

	& .chip {
		background-color: $success-color;
		margin: 0.2rem;
	}

	&-ne {
		border-bottom: 1px solid $error-color;
	}

	&-so {
		border-bottom: 1px solid #c37e07;
	}

	&-go {
		border-bottom: 1px solid $success-color;
	}
}


#PPMiniCart {
	& form {
		background-color: $background-color !important;
		border: 1px solid $line-color !important;
	}

	& ul {
		background-color: $background-color !important;
	}

	& .minicart {
		&-item {
			& + .minicart-item {
				border-top: 1px solid $line-color !important;
			}

			& a {
				color: $dark-theme-color !important;
			}
		}

		&-submit {
			background: none !important;
			border: none !important;
			height: inherit !important;
			margin-right: 0 !important;
			min-width: 185px !important;
		}
	}


}

.minicart {
	&-closer,
	&-quantity{
		color: $dark-theme-color;
	}

	&-empty-text {
		text-align: center;
	}

	&-price {
		color: $primary-color;
		font-weight: bold;
	}

	&-subtotal {
		color: $secondary-color;
	}
}

// for mobile devices
@media #{$small-and-down} {

	#PPMiniCart {
		left: 0% !important;
		top: 30% !important;
		width: 100%;
		z-index: 70;

		& ul {
			width: 100% !important;
		}

		& form {
			width: 100% !important;
			top: 30% !important;
			margin-left: 0 !important;
			max-height: 100% !important;
		}

		& .minicart-submit {
			height: 43px !important;
			width: 100px !important;
			bottom: 8px !important;
			right: -5px !important;
			min-width: 155px !important;

			& img {
				width: 90% !important;
			}
		}
	}
}

// for tablet devices
@media #{$medium-and-up} {

	#PPMiniCart {
		left: 50% !important;
		z-index: 70;

		& ul {
			width: 100% !important;
		}

		& form {
			top: 30% !important;
			max-height: 100% !important;
		}
	}
}


.hws-cart {
	margin-top: 100px;
}

.input-field {
	& input:not([type]),
	& input[type=text],
	& input[type=password],
	& input[type=email],
	& input[type=url],
	& input[type=time],
	& input[type=date],
	& input[type=datetime],
	& input[type=datetime-local],
	& input[type=tel],
	& input[type=number],
	& input[type=search] {
		height: 4rem !important;
	}
}

.card.small .card-content, .card.medium .card-content, .card.large .card-content, .card {
	overflow: visible;
}

.card .card-image .card-title {
	background-color: $primary-color-dark;
	font-weight: bold;
	text-align: center;
	width: 100%;
}

.card.large {
	height: 760px !important;
}

#viewcart {
	cursor: pointer;
}

.own-planet-card {
	overflow: visible !important;
}
