$block: '.commands';

#{$block} {
	&-text {
		color: $dark-theme-color;
		padding-top: 70px;
	}

	&-table td,
	&-table th {
		&:nth-child(1) {
			text-align: right;
		}

		&:nth-child(2) {
			text-align: left;
		}
	}

	&-label.pinned {
		left: 15% !important;
		top: 85px !important;
	}

	&-search {
		background-color: $background-color !important;
		font-weight: bold;

		&:focus {
			color: $dark-theme-color !important;
		}
	}

	&-search.pinned {
		width: 70% !important;
		padding: 0 15px;
	}

	&-separator {
		font-size: 20px;
		font-weight: bold;
		text-align: center;
	}

	&-title {
		color: $primary-color;
		font-weight: bold;
	}

	// for smartphones devices
	@media #{$small-and-down} {
		&-search.pinned {
			top: 60px !important;
			width: 100% !important;
			left: 0 !important;
		}

		&-label.pinned {
			top: 75px !important;
			left: 4% !important;
		}

		&-table td,
		&-table th {
			&:nth-child(1) {
				text-align: left;
				width: 50%;
				display: inline-block;
			}

			&:nth-child(2) {
				text-align: left;
				width: 50%;
				display: inline-block;
			}
		}
	}

	// for tablet devices
	@media #{$medium-only} {
		&-search.pinned {
			width: 85% !important;
		}

		&-label.pinned {
			left: 8% !important;
			top: 85px !important;
		}
	}

	// for desktop devices
	@media #{$large-and-up} {
		&-label.pinned {
			left: 17% !important;
		}
	}
}

