html {
	background-color: $white;
}

html, body {
	height: 100%;
	display: flex;
	flex-direction: column;
}

body {
	display: flex;
	flex-direction: column;
}
