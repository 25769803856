$block: '.connect';
$modifier: '--dynamic';

#{$block} {
	padding-top: 70px;

	&-teaser {
		text-shadow: 1px 1px 1px $background-color;
	}

	&-wrapper {
		color: $dark-theme-color;
		padding-top: 70px;

		& a {
			text-decoration: none;
		}
	}
}




/*=================================================
	   HWS Connect
==================================================*/
$block: '.hws-connect';

#{$block} {
	&-forum-loader,
	&-am-info,
	&-ocd-info,
	&-npc-info,
	&-mps-info,
	&-mp-info,
	&-uni-info,
	&-sc-info,
	&-intr-info,
	&-elb-info,
	&-egss-info,
	&-hb-info,
	&-tax-info,
	&-intrClear,
	&-intrLabel,
	&-banned,
	&-out,
	&-dashboard,
	&-loader,
	&-back-to-dashboard,
	&-server-wrapper-reload,
	&-server-wrapper-refetch,
	&-hws2dMap,
	&-refresh,
	&-daily,
	&-action-intruder,
	&-noTax,
	&-hideT,
	&-class-calculator,
	&-coMsg--eu,
	&-coMsg--sanctuary,
	&-coMsg--na,
	&-dailyLootSync,
	&-export-ocd,
	&-npc-intro,
	&-all,
	&-red,
	&-blue,
	&-green,
	&-yellow,
	&-idS,
	&-lootCheck,
	&-hwsContent {
		display: none;
	}

	&-coMsg {
		&--eu,
		&--sanctuary,
		&--na {
			padding: 15px;
		}
	}

	&-am {
		&-info {
			&Text {
				color: $dark-theme-color;
			}
		}
	}

	&-daily {
		&Loot {
			&Amount {
				color: $primary-color;
				font-size: 20px;
				font-weight: bold;
			}
		}
	}

	&-npc {
		&Table {
			display: table-row;

			&--right {
				text-align: right;
			}
		}

		&-intro {
			margin: 15px;
		}
	}

	&-button {
		background: $primary-color;
		border: 3px solid $primary-color;
		box-shadow: 0 0 0 $primary-color,0 5px 0 $primary-color-dark,0 5px 0 $primary-color-dark;
		color: $white;
		cursor: pointer;
		font-size: 16px !important;
		font-weight: 700 !important;
		height: 50px !important;
		line-height: 22px;
		margin: 0 auto;
		padding: 0 10px !important;
		position: relative;
		text-align: center;
		text-decoration: none;
		transition: all 150ms ease;
		-webkit-transition: all 150ms ease;

		&:hover {
			background: transparent;
			color: $white;
			text-decoration: none;
		}

		&:active {
			animation: none;
			-webkit-animation: none;
			box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
			transform: translateY(3px);
			-webkit-transform: translateY(3px);
		}

		&:focus {
			background: $primary-color;
		}

		&--neutral {
			background: $secondary-color;
			border: 3px solid $secondary-color;
			box-shadow: 0 0 0 $secondary-color,0 5px 0 $secondary-color-dark,0 5px 0 $secondary-color-dark;
			line-height: 40px !important;

			&:hover {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&:active {
				animation: none;
				-webkit-animation: none;
				box-shadow: 0 1px 0 $secondary-color, 0 2px 0 $secondary-color-dark, 0 2px 2px $secondary-color-dark;
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}

			&:focus {
				background: $secondary-color !important;
				color: $light-theme-color !important;
			}
		}

		&--invert {
			background: $light-theme-bg-color;
			border: 3px solid $light-theme-bg-color;
			box-shadow: 0 0 0 $light-theme-bg-color,0 5px 0 $light-theme-bg-color-dark,0 5px 0 $light-theme-bg-color-dark;
			color: $light-theme-color;
			cursor: pointer;
			font-size: 16px !important;
			font-weight: 700 !important;
			height: 50px !important;
			line-height: 22px;
			margin: 0 auto;
			padding: 0 10px !important;
			position: relative;
			text-align: center;
			text-decoration: none;
			transition: all 150ms ease;
			-webkit-transition: all 150ms ease;

			&:hover {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&:active {
				animation: none;
				-webkit-animation: none;
				box-shadow: 0 1px 0 $light-theme-bg-color, 0 2px 0 $light-theme-bg-color-dark, 0 2px 2px $light-theme-bg-color-dark;
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}

			&:focus {
				background: $light-theme-bg-color !important;
				color: $light-theme-color !important;
			}
		}

		&--negative {
			background: $error-color;
			border: 3px solid $error-color;
			box-shadow: 0 0 0 $error-color,0 5px 0 $error-color-dark,0 5px 0 $error-color-dark;
			margin: initial;

			&:hover {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&:active {
				animation: none;
				-webkit-animation: none;
				box-shadow: 0 1px 0 $error-color, 0 2px 0 $error-color-dark, 0 2px 2px $error-color-dark;
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}

			&:focus {
				background: $error-color !important;
			}
		}



		&--info {
			background: $info-color;
			border: 3px solid $info-color;
			box-shadow: 0 0 0 $info-color,0 5px 0 $info-color-dark,0 5px 0 $info-color-dark;
			margin: initial;

			&:hover {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&:active {
				animation: none;
				-webkit-animation: none;
				box-shadow: 0 1px 0 $info-color, 0 2px 0 $info-color-dark, 0 2px 2px $info-color-dark;
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}

			&:focus {
				background: $info-color !important;
			}
		}
	}

	&-profileDd {
		display: block;
		line-height: 45px;
		width: 90%;
	}

	&-card {
		&Title {
			font-size: 20px;
		}

		&Content {
			color: $dark-theme-color;
			text-align: center;

			& p {
				font-size: 16px;
			}
		}
	}

	&-new {
		background-color: #d0095b !important;
	}

	&-lottery {
		color: $secondary-color;
		font-size: 1.3rem;

		&-nr {
			font-size: 1.2rem;
		}

		&Pot {
			color: $primary-color;
			font-weight: bold;
		}
	}

	&-co-msg {
		font-size: 16px;
		font-weight: bold;
		margin-top: 30px;
	}

	&-textarea {
		&:focus:not([readonly]) {
			border-bottom: 1px solid $white !important;
		}

		&:focus:not([readonly])+label {
			color: $white !important;
		}

		&Error {
			border-bottom: 3px solid $error-color !important;
		}
	}

	&-npc {
		&Wrapper {
			display: inline-block;
			padding-left: 5px;
			position: relative;

			&Button {
				margin: 10px;
			}
		}

		&Count {
			bottom: 7px;
			font-size: 12px;
			position: absolute;
		}
	}

	&-title {
		font-size: 3rem;
	}

	&-hotviewWrapper {
		padding-bottom: 10px;
	}

	&-teaserTitle {
		font-size: 37px;
		font-weight: bold;
	}

	&-changelog {
		color: $primary-color;
		text-decoration: underline;
	}

	&-name,
	&-ocdLvl,
	&-pffac {
		color: $success-color;
		text-decoration: none;
	}

	&-steam {
		&Link {
			font-weight: bold;
		}

		&Avatar {
			border-radius: 50%;
			margin-left: 5px;
			vertical-align: middle;
		}

	}

	&-avatar {
		margin-top: 13px;
	}

	&-server {
		border: 1px solid $success-color;
		border-radius: 3px;
		height: 100%;
		margin: 10px;
		max-height: 100% !important;
		position: relative;
		text-align: center;
		transition: all .3s;

		&Card {
			height: 300px;

			&Title {
				background-color: rgba(9, 17, 21, 0.78);
				bottom: 0;
				color: $white;
				font-size: 30px;
				padding: 10px;
				position: absolute;
				width: 100%;
				z-index: 77;
			}

			&Desc {
				background-color: rgba(9, 17, 21, 0.78);
				margin: 0;
				padding: 10px;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		&--na {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-na.jpg");
			background-size: cover;
			&:hover {
				background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-na-h.jpg");
			}
		}

		&--eu {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-eu.jpg");
			background-size: cover;
			&:hover {
				background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-eu-h.jpg");
			}
		}

		&--sanctuary {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/sanctuary.jpg");
			background-size: cover;
			&:hover {
				background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/sanctuary-h.jpg");
			}
		}

		&--player {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/player-stat.jpg");
			background-size: cover;
		}

		&--playerLog {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/pll.jpg");
			background-size: cover;
		}

		&--ocd {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/ocd.jpg");
			background-size: cover;
		}

		&--am {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/amd.jpg");
			background-size: cover;
		}

		&--intruder {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/intruder.jpg");
			background-size: cover;
		}

		&--stealth {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/egs-stealth.jpg");
			background-size: cover;
		}

		&--structure {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/fleet.jpg");
			background-size: cover;
		}

		&--tax {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/tl.jpg");
			background-size: cover;
		}

		&--chat {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-community.jpg");
			background-size: cover;
		}

		&--marketplace {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-mp.jpg");
			background-size: cover;
		}

		&--bank {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hwsc-eb.jpg");
			background-size: cover;
		}

		&--garage {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hws-mps.jpg");
			background-size: cover;
		}

		&--trader {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/npc-trader.jpg");
			background-size: cover;
		}

		&--universe {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/universe.jpg");
			background-size: cover;
		}

		&--hunter {
			background-image: url("https://empyrion-homeworld.net/user/themes/empyrion/images/hunter-board.jpg");
			background-size: cover;
		}
	}

	&-eu,
	&-na {
		display: block;
	}

	&-info,
	&-action-intruder {
		cursor: pointer;

		&Title,
		&Text {
			color: $secondary-color;
		}

		&List {
			padding-left: 20px;

			&Item {
				color: $secondary-color;
			}
		}
	}

	&-section {
		&-bb {
			background-color: rgba(32, 76, 127, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(47, 178, 230, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-bg {
			background-color: rgba(32, 76, 127, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(1, 146, 69, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-br {
			background-color: rgba(32, 76, 127, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(183, 57, 57, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-by {
			background-color: rgba(32, 76, 127, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(138, 126, 51, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-gb {
			background-color: rgba(1, 146, 69, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(47, 178, 230, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-gg {
			background-color: rgba(1, 146, 69, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(1, 146, 69, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-gr {
			background-color: rgba(1, 146, 69, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(183, 57, 57, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-gy {
			background-color: rgba(1, 146, 69, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(138, 126, 51, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-rb {
			background-color: rgba(183, 57, 57, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(47, 178, 230, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-rg {
			background-color: rgba(183, 57, 57, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(1, 146, 69, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-rr {
			background-color: rgba(183, 57, 57, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(183, 57, 57, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-ry {
			background-color: rgba(183, 57, 57, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(138, 126, 51, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-yb {
			background-color: rgba(138, 126, 51, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(47, 178, 230, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-yg {
			background-color: rgba(138, 126, 51, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(1, 146, 69, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-yr {
			background-color: rgba(138, 126, 51, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(183, 57, 57, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}

		&-yy {
			background-color: rgba(138, 126, 51, 0.33);

			& > .hws-connect-mpPrice {
				color: rgba(138, 126, 51, 1);
				text-shadow: 0px 0px 1px #000;
			}
		}
	}

	&-mp {
		background: none;
		border: none;
		height: 10px;
		width: 10px;

		&Amount {
			color: $success-color;
			font-size: 1.3rem;
			font-weight: bold;
			text-align: right;
		}

		&Seller {
			text-align: right;
		}


		&Price {
			color: $success-color;
			font-size: 2rem;
			font-weight: bold;
			text-align: right;

			&Sub {
				color: $secondary-color;
				font-weight: bold;
				text-align: right;
			}
		}
	}

	&-bounty {
		color: $success-color;
		font-size: 2rem;
		font-weight: bold;
		text-align: right;
	}


	&-dbc {
		& .card-image {
			max-height: 60% !important;
		}
	}

	&ed-wrapper {
		margin: 0 10px;
	}

	@media #{$small-and-down} {
		&-server {
			&Card {
				height: 100px;

				&Title {
					font-size: 30px;
					background-color: transparent;
				}

				&Desc {
					display: none;
				}
			}
		}

		&-wrapper {
			overflow: hidden;
		}

		&-content {
			overflow-x: scroll;
		}
	}

	@media #{$medium-and-down} {
		&-am-info,
		&-ocd-info,
		&-npc-info,
		&-mps-info,
		&-mp-info,
		&-uni-info,
		&-sc-info,
		&-intr-info,
		&-elb-info,
		&-hb-info,
		&-tax-info,
		&-daily {
			margin-top: 10px;
		}

		&-wrapper {
			overflow: hidden;
		}

		&-content {
			overflow-x: scroll;
		}
	}
}

.select-dropdown {
	padding-left: 0 !important;

	& li {
		list-style: none !important;
	}
}

.steam-login {
	display: block;
}

.ct {
	margin-top: 5px;

	&:hover {
		background-color: $background-color !important;
	}
}

.hws-connect-hwscWrapper {
	display: none;
}

.hws-connected-link {
	width: 100%;
}

.hotview {
	display: none;
	font-size: .8rem;
	text-align: left !important;

	&-modal {
		& h1,
		& h2,
		& h3,
		& h4,
		p {
			color: $dark-theme-color;
		}
	}

	&-1,
	&-2,
	&-3,
	&-4,
	&-5,
	&-6,
	&-7,
	&-8,
	&-9,
	&-10,
	&-11,
	&HeaderInfo {
		display: none;
	}

	&-content {
		display: inline-block;

		&Text {
			font-size: .9rem !important;
			line-height: 2.1;
		}
	}

	&Header {
		min-height: 30px !important;
		text-align: left !important;

		&:hover {
			background: none !important;
			cursor: default;
		}
	}
}

.spu {
	background-color: $success-color !important;
}

.spue {
	background-color: $error-color !important;
}

.hwsc {
	&-logout {
		margin-top: 8px;
		width: 100%;
	}

	&-content {
		left: 0 !important;
		padding: 10px 10px !important;
		width: 100% !important;

		& li {
			text-align: center;
		}
	}
}

.hws-mpTh {
	text-align: right;
}

@media #{$medium-and-down} {
	.hwsc {
		&-content {
			width: 100% !important;
		}
	}
}

.cog-option-icon {
	line-height: inherit !important;
}

th.header:hover, th.header:hover>.table-filter {
	background-color: $success-color;
}

#header-fixed {
	background-color: #000000;
	border: none;
	display: none;
	max-width: 1238px;
	position: fixed;
	top: 0;
	width: auto;
}

.select-wrapper+label {
	top: -18px;
}

.hm {
	background: none;
	border: none;
}

.hws-connect-wrapper .card.small {
	height: 400px;
	transition: all 150ms ease;
	-webkit-transition: all 150ms ease;

	&:hover {
		border: 3px solid $secondary-color;
	}
}

.hws-connect-wrapper .card .card-image {
	position: initial;
}

.side-nav li>a:hover {
	color: $white;

	& .cog-option-icon {
		color: $white;
	}
}

html.hws-connect-sanctuaryServer .hws-connect-economy {
	display: none;
}