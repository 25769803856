$block: '.discord';
$modifier: '--dynamic';

#{$block} {
	&-widget {
		margin: 40px 15px !important;
	}

	&-title {
		text-align: center;

		& h3 {
			font-size: 20px !important;
			font-weight: 400 !important;
		}
	}

	&-allusers {
		height: 240px;
		overflow: auto;
	}

	&-users-online {
		color: $secondary-color;
	}

	&-allusers-toggle {
		cursor: pointer !important;
	}

	&-join {
		background: $primary-color !important;
		border: 3px solid $primary-color !important;
		box-shadow: 0 0 0 $primary-color, 0 5px 0 $primary-color-dark, 0 5px 0 $primary-color-dark;
		color: $white;
		cursor: pointer;
		display: block;
		font-size: 18px;
		font-weight: 700 !important;
		text-align: center;
		height: 50px !important;
		padding-top: 5px !important;
		position: absolute;
		text-decoration: none;
		transition: all 150ms ease;
		-webkit-transition: all 150ms ease;

		& a {
			display: block;
			padding: 0 !important;
		}

		&:hover {
			background: transparent !important;
			color: $white;
			text-decoration: none;
		}

		&:active {
			animation: none;
			-webkit-animation: none;
			box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
			transform: translateY(3px);
			-webkit-transform: translateY(3px);
		}

		&:focus {
			background: $primary-color !important;
		}
	}
}