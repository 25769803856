$module: '.ecc';

#{$module} {
	padding-top: 70px;
	color: $dark-theme-color;

	&-label {
		&.active {
			color: $white;
		}
	}

	&-calc {
		background: $primary-color;
		border: 3px solid $primary-color;
		box-shadow: 0 0 0 $primary-color, 0 5px 0 $primary-color-dark, 0 5px 0 $primary-color-dark;
		color: $white;
		cursor: pointer;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		height: 50px;
		padding-top: 5px;
		text-decoration: none;
		transition: all 150ms ease;
		-webkit-transition: all 150ms ease;

		& a {
			padding: 16px 90px;
		}

		&:hover {
			background: transparent;
			color: $white;
			text-decoration: none;
		}

		&:active {
			color: $white;
			animation: none;
			-webkit-animation: none;
			box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
			transform: translateY(3px);
			-webkit-transform: translateY(3px);
		}

		&:focus {
			background: $primary-color;
		}
	}

	&-result {
		padding: 20px 0 !important;

		&Number {
			color: $dark-theme-color;

			&--class {
				color: $primary-color;
			}
		}
	}

	&-info {
		color: $secondary-color;
		font-size: 24px;
		font-weight: bold;
	}

	&-price {
		font-weight: bold;

		&-info {
			display: block;
		}
	}

	&-loader {
		display: none;
	}

	&-input {
		&:focus:not([readonly]) {
			border-bottom: 1px solid $white !important;
		}

		&Error {
			border-bottom: 3px solid $error-color !important;
		}
	}

	&-supporterTitle {
		color: $secondary-color;
		font-weight: bold;
		padding: 10px 0 0 0;
	}

	&-supporterHeaderS {
		color: $secondary-color;
	}

	&-result-re,
	&-result-bp {
		display: none;
		margin-top: 30px;
	}

	&-supporterBody,
	&-loader {
		margin-top: 1rem;
	}

	&-supporterBodyGet {
		display: none;
	}

	& .chip {
		background-color: $success-color;
		margin: 0.2rem;
	}

	&-ne {
		border-bottom: 1px solid $error-color;
	}

	&-so {
		border-bottom: 1px solid #c37e07;
	}

	&-go {
		border-bottom: 1px solid $success-color;
	}
}