@charset "UTF-8";

// Mixins
// @import "components/prefixer";
@import "../materialize/sass/components/mixins";
@import "../materialize/sass/components/color";

// Variables;
@import "settings-variables";
@import "../materialize/sass/components/variables";

// Reset
@import "../materialize/sass/components/normalize";

// components
@import "../materialize/sass/components/global";
@import "../materialize/sass/components/icons-material-design";
@import "../materialize/sass/components/grid";
@import "../materialize/sass/components/navbar";
@import "../materialize/sass/components/typography";
@import "../materialize/sass/components/cards";
@import "../materialize/sass/components/toast";
@import "../materialize/sass/components/tabs";
@import "../materialize/sass/components/tooltip";
@import "../materialize/sass/components/buttons";
@import "../materialize/sass/components/dropdown";
@import "../materialize/sass/components/waves";
@import "../materialize/sass/components/modal";
@import "../materialize/sass/components/collapsible";
@import "../materialize/sass/components/chips";
@import "../materialize/sass/components/materialbox";
@import "../materialize/sass/components/forms/forms";
@import "../materialize/sass/components/table_of_contents";
@import "../materialize/sass/components/sideNav";
@import "../materialize/sass/components/preloader";
@import "../materialize/sass/components/slider";
@import "../materialize/sass/components/carousel";
@import "../materialize/sass/components/date_picker/default";
@import "../materialize/sass/components/date_picker/default.date";
@import "../materialize/sass/components/date_picker/default.time";


// own styles
@import "block";
@import "block-typography";
@import "block-header";
@import "block-sidebar";
@import "block-footer";
@import "element-home";
@import "element-slider";
@import "element-connect";
@import "element-commands";
@import "element-class-calculator";
@import "element-discord";
@import "element-donation";
@import "modifier";
@import "modifier-theme";
@import "../font-awesome/font-awesome";
