::selection {
	background: $primary-color;
	color: $white;
	text-shadow: 0 0 0 $white;
}

::-moz-selection {
	background: $primary-color;
	color: $white;
	text-shadow: 0 0 0 $white;
}

.discord-userlist::-webkit-scrollbar {
	background-color: $background-color-dark;
	width: 10px;
}

.discord-userlist::-webkit-scrollbar-track {
	-webkit-box-shadow: #fff;
}

.discord-userlist::-webkit-scrollbar-thumb {
	background-color: $primary-color-dark;
	outline: 1px solid $primary-color-dark;
}

#sidenav-overlay {
	z-index: 10;
}

.drag-target {
	width: 10px !important;
}

@-webkit-keyframes pulsate {
	0% {
		-webkit-transform: scale(0.1, 0.1);
		opacity: 0.0;
		transform: scale(0.1, 0.1);
	}
	50% {
		opacity: 1.0;
	}
	100% {
		-webkit-transform: scale(1.2, 1.2);
		opacity: 0.0;
		transform: scale(1.2, 1.2);
	}
}

@-webkit-keyframes slide {
	0% {
		right: 5px;
	}

	50% {
		right: 10px;
	}

	100% {
		right: 5px;
	}
}

.parallax-container {
	z-index: 1;
}

.parallax-container .parallax img {
	opacity: 0;
	transition: opacity .3s ease-in;
}

.hws-forum-loader {
	display: none;
}

.switch label .lever {
	background-color: $dark-theme-color;
}

.card,
.select-dropdown.dropdown-content li:hover,
.card .card-reveal {
	background-color: $background-color-dark;
}

.select-dropdown li.optgroup {
	color: $dark-theme-color;
}

.dropdown-content li {
	font-weight: bold;
}

.card-title {
	font-weight: bold;
}

.card-reveal .donation-calc {
	padding-bottom: 25px;
}

.donation-label .active,
.select-dropdown li.disabled,
.select-dropdown li.disabled>span {
	color: $line-color;
}

.select-dropdown li.disabled:hover {
	background-color: $background-color;
}

.ts-header {
	cursor: pointer;

	&:hover {
		background-color: $background-color-dark;
	}
}

.modal .modal-footer {
	background-color: $background-color;
	margin-bottom: 15px;
}

#error h1 {
	color: $dark-theme-color;
}

.card .card-title {
	font-size: 18px;
}

@media only screen and (max-width: 992px) {
	.side-nav a {
		padding: 0 !important;
	}
}

.need-help {
	display: block;
}

.scroll-top {
	border: 2px solid $success-color;
	display: none;
	bottom: 1%;
	cursor: pointer;
	height: 50px;
	left: 1%;
	padding-top: 12px;
	position: fixed;
	text-align: center;
	width: 50px;
	z-index: 700;
}