$block: '.content';
$modifier: '--dynamic';

#{$block} {
	flex: 1 0 auto;

	@at-root {
		& .content-connect,
		& .content-text--features {
			ul li {
				list-style-type: disc;
			}
		}
	}

	&-sub {
		padding-top: 70px;
	}

	&-home a {
		color: $primary-color;
		text-decoration: underline;
	}

	&-text {
		color: $dark-theme-color;
		padding: 30px 0 10px;

		&Title {
			font-weight: bold;
			padding-bottom: 20px;
		}

		&Line {
			background-color: $dark-theme-color;
			border: none;
			height: 1px;
		}
	}

	&-icon {
		font-size: 50px;
	}

	&-para {
		&Content {
			color: $dark-theme-color;
			font-size: 30px;
			line-height: 25px;
			text-shadow: 1px 1px 1px $background-color;
		}

		&Button {
			background: $primary-color;
			border: 3px solid $primary-color;
			box-shadow: 0 0 0 $primary-color, 0 5px 0 $primary-color-dark, 0 5px 0 $primary-color-dark;
			color: $white;
			cursor: pointer;
			display: block;
			font-size: 18px;
			font-weight: 700;
			padding: 10px;
			text-align: center;
			position: absolute;
			text-decoration: none;
			transition: all 150ms ease;
			-webkit-transition: all 150ms ease;
			width: 150px;

			&--story1,
			&--features1 {
				left: 8%;
				top: 80%;
			}

			&--story2,
			&--features2 {
				right: 6%;
				top: 80%;
			}

			&--story3 {
				left: 8%;
				top: 80%;
			}

			&:hover {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&:active {
				animation: none;
				-webkit-animation: none;
				box-shadow: 0 1px 0 $primary-color, 0 2px 0 $primary-color-dark, 0 2px 2px $primary-color-dark;
				transform: translateY(3px);
				-webkit-transform: translateY(3px);
			}

			&:focus {
				background: $primary-color;
			}
		}

		&Title {
			position: absolute;
			&--story1,
			&--features1 {
				left: 8%;
				top: 20%;
			}

			&--story2,
			&--features2 {
				right: 6%;
				top: 20%;
			}

			&--story3 {
				left: 8%;
				top: 20%;
			}
		}

		&Text {
			position: absolute;
			width: 300px;

			&Icon {
				padding-bottom: 10px;
				position: absolute;
			}

			&--story1,
			&--features1 {
				left: 8%;
				top: 50%;
			}

			&--story2,
			&--features2 {
				right: 6%;
				top: 50%;
			}

			&--story3 {
				left: 8%;
				top: 50%;
			}

		}
	}

	&-story {
		padding-top: 10px;

		&Focus {
			color: $dark-theme-color;
			cursor: pointer;
			display: block;
			font-size: 70px;
			text-align: center;
		}
	}

	&-connect {
		padding-top: 30px;

		&Disclaimer {
			color: $secondary-color;
		}
	}

	// for mobile devices
	@media #{$small-and-down} {
		&-home {
			padding-top: 60px;
		}
	}


	// for tablet devices
	@media #{$medium-and-up} {
		&-home {
			padding-top: 90px;
		}
	}


	// for desktop devices
	@media #{$large-and-up} {
		&-home {
			padding-top: 130px;
		}
	}
}

